import React from 'react'

import { Group, PageHeading } from '@affstore-ds/uikit'

import { MainPageHeaderPropertiesType } from './MainPageHeader.types'

import { HelmetPageTitle } from '../HelmetPageTitle'

import { useMainPageHeaderStyles } from './MainPageHeader.styled'

export const MainPageHeader = ({
  title,
  helmetTitle,
  logoIcon,
  badge,
  subtitle,
  withBackButton,
  backButtonTooltip,
  onBackButtonClick,
  elementSize,
  classNames,
  ...rest
}: MainPageHeaderPropertiesType) => {
  const { classes, cx } = useMainPageHeaderStyles()

  const titleWithBadge = (
    <Group>
      {title} {badge}
    </Group>
  )

  return (
    <>
      {title && <HelmetPageTitle helmetTitle={helmetTitle} pageTitle={title} />}
      <PageHeading
        elementSize={elementSize}
        title={badge ? titleWithBadge : title}
        withLogo={Boolean(logoIcon)}
        logoIcon={logoIcon}
        subtitle={subtitle}
        withBackButton={withBackButton}
        backButtonTooltip={backButtonTooltip}
        onBackButtonClick={onBackButtonClick}
        classNames={{ ...classNames, subtitle: cx(classes.subtitle, classNames?.subtitle) }}
        {...rest}
      />
    </>
  )
}

import { createStyles, rem } from '@affstore-ds/uikit'

import { HEADER_HEIGHT } from '../../constants/spaces'

export const useErrorPageStyles = createStyles((theme, { isOuterErrorPath }: { isOuterErrorPath: boolean }) => ({
  pageContainer: {
    width: '100%',
    height: isOuterErrorPath ? '100vh' : `calc(${theme.other.viewport.height} - ${rem(HEADER_HEIGHT * 2)})`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

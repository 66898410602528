import React, { memo, PropsWithChildren, useEffect, useState } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { AnalyticsProvider } from '@affiliate-frontend-analytics/events'

import { getBrandConfig } from '../../config/brands'
import { createEventConfig } from '../../config/events'
import { initGtm } from '../../shared/gtm/initGtm'

const { brandConfig } = getBrandConfig()

const GA_MEASUREMENT_ID = brandConfig.gtmId || undefined

export const AppAnalyticsProvider = memo(({ children }: PropsWithChildren) => {
  const [previousLocale, setPreviousLocale] = useState<null | string>(null)
  const {
    i18n: { isTranslationsLoading, locale },
  } = useI18n()

  useEffect(() => {
    const currentLocale = locale()

    if (!currentLocale || currentLocale === previousLocale) {
      return
    }

    createEventConfig({ gaMeasurementId: GA_MEASUREMENT_ID, locale: currentLocale })
    setPreviousLocale(currentLocale)
  }, [isTranslationsLoading, locale, previousLocale])

  useEffect(() => {
    if (GA_MEASUREMENT_ID) {
      initGtm(GA_MEASUREMENT_ID)
    }
  }, [])

  return <AnalyticsProvider>{children}</AnalyticsProvider>
})

AppAnalyticsProvider.displayName = 'AppAnalyticsProvider'

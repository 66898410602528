import React from 'react'
import { DateTime } from 'luxon'

import {
  AlignBottom0213,
  BarChartSquare0113,
  BookOpen0213,
  CodeCircle0313,
  Image0113,
  ImageIndentLeft13,
  Tag0113,
  Trophy0113,
  UsersPlus13,
  UserSquare13,
  Wallet0213,
} from '@affstore-ds/icons'
import { NavMenuLinkInterface } from '@affstore-ds/uikit'

import { featuresStatuses } from '../../config/brands'
import { routes } from '../routes'
import { navMenuTranslationKeys } from '../translations/NavMenu.translationKeys'

export const profileTabSettings = {
  personal: 'personal',
  contactInfo: 'contact-info',
}

export const profileTabSettingsPersonal = routes.ROUTES_PROFILE.profileSettingsTab.replace(':tabValue', profileTabSettings.personal)

const offerRulesTabSettings = {
  ruleRestriction: 'rules-general-restrictions',
  ruleMarketingGuidelines: 'rules-marketing-guidelines',
  ruleTrafficTypes: 'rules-traffic-types',
  ruleNegativeKeywords: 'rules-negative-keywords',
  ruleAppsGuidelines: 'rules-apps-guidelines',
  rulePaymentTerms: 'rules-payment-terms',
}

export const offerTabSettings = {
  links: 'links',
  activation: 'activation',
  rates: 'rates',
  advertiser: 'advertiser',
  rules: offerRulesTabSettings,
  backlink: 'backlink',
}

export const paymentsTabSettings = {
  regularAndRequests: 'regular-and-requests',
  history: 'history',
  settings: 'settings',
}

export const paymentsTabSettingsRegularAndRequests = routes.ROUTES_PAYMENTS.paymentsTab.replace(':tabValue', paymentsTabSettings.regularAndRequests)

export const promoTabSettings = {
  landings: 'landings',
  banners: 'banners',
  videos: 'videos',
  registrationFrames: 'registration-frames',
  backlinks: 'backlinks',
}

const promoTabSettingsLandings = routes.ROUTES_PROMO.promoTab.replace(':tabValue', promoTabSettings.landings)

const displayNewBadgeUpToDateForTop10Page = DateTime.fromObject({ year: 2024, month: 4, day: 16 }).plus({ day: 45 }).toMillis()
const displayNewBadgeUpToDateForPostbacksPage = DateTime.fromObject({ year: 2024, month: 6, day: 16 }).plus({ day: 45 }).toMillis()
const displayNewBadgeUpToDateForBlogLink = DateTime.fromObject({ year: 2024, month: 6, day: 28 }).plus({ day: 45 }).toMillis()
const displayNewBadgeUpToDateForContestsPage = DateTime.fromObject({ year: 2024, month: 9, day: 24 }).plus({ day: 45 }).toMillis()

export const NAV_MENU_DATA: NavMenuLinkInterface[] = [
  // TODO: revert after dashboard in release
  // {
  //   value: routes.routes.ROUTES_DASHBOARD.dashboard,
  //   labelTranslationKey: navMenuTranslationKeys.navMenuItemDashboard,
  //   icon: <Grid0113 strokeWidth={1.6} />,
  // },
  {
    label: navMenuTranslationKeys.navMenuItemStatistics,
    icon: <BarChartSquare0113 strokeWidth={1.6} />,
    component: 'button',
    withChildren: true,
    children: [
      { value: routes.ROUTES_STATISTICS.general, label: navMenuTranslationKeys.navMenuItemStatisticsGeneral, dataTestId: 'statistics-general' },
      { value: routes.ROUTES_STATISTICS.users, label: navMenuTranslationKeys.navMenuItemStatisticsUsers, dataTestId: 'statistics-by-users' },
    ],
    value: routes.ROUTES_STATISTICS.root,
    dataTestId: 'statistics',
  },
  ...(featuresStatuses.isNewOffersPageEnabled
    ? [
        {
          value: routes.ROUTES_OFFERS.offers,
          label: navMenuTranslationKeys.navMenuItemOffers,
          icon: <Tag0113 strokeWidth={1.6} />,
          component: 'button',
          withChildren: true,
          dataTestId: 'offers',
          children: [
            {
              value: routes.ROUTES_OFFERS.offersAdvertisers,
              label: navMenuTranslationKeys.navMenuItemOffersByAdvertisers,
              dataTestId: 'offers-by-advertisers',
            },
            { value: routes.ROUTES_OFFERS.offers, label: navMenuTranslationKeys.navMenuItemOffersByOffers, dataTestId: 'offers-by-offers' },
          ],
        },
      ]
    : [
        {
          value: routes.ROUTES_OFFERS.offers,
          label: navMenuTranslationKeys.navMenuItemOffers,
          icon: <Tag0113 strokeWidth={1.6} />,
          component: 'a',
          dataTestId: 'offers',
        },
      ]),
  ...(featuresStatuses.isPromoPageEnabled
    ? [
        {
          value: promoTabSettingsLandings,
          label: navMenuTranslationKeys.navMenuItemPromo,
          icon: <Image0113 strokeWidth={1.6} />,
          dataTestId: 'promo',
        },
      ]
    : []),
  {
    value: paymentsTabSettingsRegularAndRequests,
    label: navMenuTranslationKeys.navMenuItemPayments,
    icon: <Wallet0213 strokeWidth={1.6} />,
    dataTestId: 'payments',
  },
  ...(featuresStatuses.isTop10PageEnabled
    ? [
        {
          value: routes.ROUTES_TOP.root,
          label: navMenuTranslationKeys.navMenuItemTop,
          icon: <Trophy0113 strokeWidth={1.6} />,
          counter: displayNewBadgeUpToDateForTop10Page,
          dataTestId: 'top',
        },
      ]
    : []),
  ...(featuresStatuses.isContestsPageEnabled
    ? [
        {
          value: routes.ROUTES_CONTESTS.tabsPath.replace(routes.ROUTES_CONTESTS_TABS._paramsKey, routes.ROUTES_CONTESTS_TABS.active),
          label: navMenuTranslationKeys.navMenuItemContests,
          icon: <AlignBottom0213 strokeWidth={1.6} />,
          counter: displayNewBadgeUpToDateForContestsPage,
          dataTestId: 'contests',
        },
      ]
    : []),
  ...(featuresStatuses.isInfoPageEnabled
    ? [
        {
          value: routes.ROUTES_INFO.info,
          label: navMenuTranslationKeys.navMenuItemInfo,
          icon: <BookOpen0213 strokeWidth={1.6} />,
          external: true,
          dataTestId: 'info',
        },
      ]
    : []),
  ...(featuresStatuses.isBlogLinkEnabled
    ? [
        {
          value: routes.ROUTES_INFO.blog,
          label: navMenuTranslationKeys.navMenuItemBlog,
          icon: <ImageIndentLeft13 strokeWidth={1.6} />,
          counter: displayNewBadgeUpToDateForBlogLink,
          external: true,
          dataTestId: 'blog',
        },
      ]
    : []),
  ...(featuresStatuses.isSubAffiliatePageEnabled
    ? [
        {
          value: routes.ROUTES_SUB_AFFILIATE.subAffiliate,
          label: navMenuTranslationKeys.navMenuItemSubAffiliate,
          icon: <UsersPlus13 strokeWidth={1.6} />,
          component: 'a',
          dataTestId: 'sub-affiliate',
        },
      ]
    : []),
  ...(featuresStatuses.isTelegramPostbacksEnabled
    ? [
        {
          value: routes.ROUTES_POSTBACKS.postbacksAll,
          label: navMenuTranslationKeys.navMenuItemPostbacks,
          icon: <CodeCircle0313 strokeWidth={1.6} />,
          counter: displayNewBadgeUpToDateForPostbacksPage,
          component: 'button',
          withChildren: true,
          dataTestId: 'postbacks',
          children: [
            {
              value: routes.ROUTES_POSTBACKS.postbacksList,
              label: navMenuTranslationKeys.navMenuItemPostbacksGeneral,
              component: 'a',
              dataTestId: 'postbacks-general',
            },
            {
              value: routes.ROUTES_POSTBACKS.postbacksTelegram,
              label: navMenuTranslationKeys.navMenuItemPostbacksTelegram,
              counter: displayNewBadgeUpToDateForPostbacksPage,
              component: 'a',
              dataTestId: 'postbacks-telegram',
            },
          ],
        },
      ]
    : [
        {
          value: routes.ROUTES_POSTBACKS.postbacksList,
          label: navMenuTranslationKeys.navMenuItemPostbacks,
          icon: <CodeCircle0313 strokeWidth={1.6} />,
          component: 'a',
          dataTestId: 'postbacks',
        },
      ]),
  {
    value: routes.ROUTES_PROFILE.profile,
    label: navMenuTranslationKeys.navMenuItemProfile,
    icon: <UserSquare13 strokeWidth={1.6} />,
    component: 'button',
    withChildren: true,
    dataTestId: 'profile',
    children: [
      ...(featuresStatuses.isKYCEnabled
        ? [
            {
              value: routes.ROUTES_PROFILE.profileKYC,
              label: navMenuTranslationKeys.navMenuItemProfileKyc,
              component: 'a',
              dataTestId: 'profile-kyc',
            },
          ]
        : []),
      {
        value: routes.ROUTES_PROFILE.profileSecurity,
        label: navMenuTranslationKeys.navMenuItemProfileSecurity,
        component: 'a',
        dataTestId: 'profile-security',
      },
      {
        value: profileTabSettingsPersonal,
        label: navMenuTranslationKeys.navMenuItemProfileSettings,
        component: 'a',
        dataTestId: 'profile-settings',
      },
    ],
  },
]

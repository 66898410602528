import React from 'react'

import { getBrandConfig } from '../../../../../../config/brands'
import { AdvertiserLogo, type AdvertiserLogoType } from '../../../../../../shared/assets'

const DESKTOP_LOGO_WIDTH = 144
const DESKTOP_NIRION_LOGO_WIDTH: Partial<Record<AdvertiserLogoType, number>> = {
  LogoNyrion: 86,
}
const MOBILE_LOGO_SIZE = 32

const { brandConfig } = getBrandConfig()

export const MainLogo = () => (
  <>
    <AdvertiserLogo
      size="m"
      iconName={brandConfig.logo}
      h="auto"
      w={DESKTOP_NIRION_LOGO_WIDTH[brandConfig.logo] || DESKTOP_LOGO_WIDTH}
      mah={40}
      visibleFrom="sm"
    />
    <AdvertiserLogo size="s" iconName={brandConfig.logo} h={MOBILE_LOGO_SIZE} w={MOBILE_LOGO_SIZE} hiddenFrom="sm" />
  </>
)

import React, { PropsWithChildren } from 'react'

import { Stack, StackProps } from '@affstore-ds/uikit'

import { PAGE_VERTICAL_PADDING } from '../../constants/spaces'

/**
 * This component should be uses as wrapper on `pages` layer
 * Do not use it with internal components/containers and other layers instead of `pages` layer
 */
export const PageStaticStack = ({ children, ...rest }: PropsWithChildren<StackProps>) => (
  <Stack
    w="100%"
    py={PAGE_VERTICAL_PADDING}
    // на всех страницах должен быть 0, так как отступы задаются сеткой (Grid.Container) в apps/main/src/app/layout/Main/index.tsx
    // Зафиксировали, что промежутки между секциями контента 16
    gap={16}
    maw={960}
    // TODO: [Responsive] Should be removed after all pages are switched to mobile view
    m={{ xs: 'unset', sm: 'unset', md: '0 auto', lg: '0 auto', xl: '0 auto' }}
    {...rest}
  >
    {children}
  </Stack>
)

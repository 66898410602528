import React, { FC, memo, Suspense, useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { RequireAuth, useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { gApageView } from '@affiliate-frontend-analytics/events'
import { Grid, Loader } from '@affstore-ds/uikit'

import { useOldLoginGuide } from '../shared/providers/TransferGuideContainer/hooks/useOldLoginGuide'

import { renderRoutingGuest, renderRoutingProtected, renderSharedRoutes } from '../pages'
import { NotFound } from '../pages/404'
import { InternalError } from '../pages/500'
import { routes } from '../shared/routes'
import { getStartPage } from '../shared/utils/getStartPage'

import { LayoutGuest, LayoutLoader, LayoutMain } from './layout'
import { AppProviders } from './providers'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const AppEntry: FC = memo(() => {
  const {
    auth: { user },
  } = useAuth()
  const { i18n } = useI18n()
  const location = useLocation()

  useOldLoginGuide()

  const getNavigationPath = () => {
    if (!user) {
      return `${routes.ROUTES_AUTH.signIn}${location.search}`
    }

    if (!user?.is_onboarding_passed) {
      return `${routes.ROUTES_ONBOARDING.onboarding}${location.search}`
    }

    return `${getStartPage()}${location.search}`
  }

  useEffect(() => {
    gApageView(location.pathname + location.search)
  }, [location])

  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({ id: user.id.toString(), language: i18n.locale() })
    } else {
      Sentry.setUser(null)
    }
  }, [i18n, user?.id])

  return (
    <LayoutLoader>
      <SentryRoutes>
        <Route path="/" element={<Navigate to={getNavigationPath()} />} />

        <Route path="/" element={<LayoutGuest />}>
          {renderRoutingGuest()}
        </Route>

        <Route
          path="/"
          element={
            <RequireAuth signInRoute={routes.ROUTES_AUTH.signIn}>
              <LayoutMain />
            </RequireAuth>
          }
        >
          {renderRoutingProtected()}
          {user && (
            <>
              <Route path={routes.ROUTES_ERROR['404']} element={<NotFound />} />
              <Route path={routes.ROUTES_ERROR['500']} element={<InternalError />} />
              <Route path="*" element={<Navigate to={routes.ROUTES_ERROR['404']} />} />
            </>
          )}
        </Route>

        <Route
          element={
            <Suspense fallback={<Loader />}>
              <Grid.Container>
                <Outlet />
              </Grid.Container>
            </Suspense>
          }
        >
          {renderSharedRoutes()}
        </Route>

        {!user && (
          <>
            <Route path={routes.ROUTES_ERROR['404']} element={<NotFound />} />
            <Route path={routes.ROUTES_ERROR['500']} element={<InternalError />} />
            <Route path="*" element={<Navigate to={routes.ROUTES_ERROR['404']} />} />
          </>
        )}
      </SentryRoutes>
    </LayoutLoader>
  )
})

AppEntry.displayName = 'AppEntry'

const App = memo(() => (
  <AppProviders>
    <AppEntry />
  </AppProviders>
))

App.displayName = 'App'

export default App

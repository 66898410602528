import React, { FC, Suspense, useEffect, useMemo } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '@affiliate-cabinet/auth'
import { Center, Grid, Loader } from '@affstore-ds/uikit'

import { useFrom } from '../../../shared/hooks'
import { routes } from '../../../shared/routes'
import { getStartPage } from '../../../shared/utils/getStartPage'

import { GuestSkeleton } from './components/GuestSkeleton'

export const Guest: FC = () => {
  const location = useLocation()

  const {
    auth: { user, isUserLoading },
  } = useAuth()

  const { returnToFromReactRouter, returnToFromURLSearch } = useFrom(getStartPage())

  const isActivate = useMemo(() => {
    const [locationEmpty, locationAuth, locationActivate, locationToken] = location.pathname.split('/')
    const [routesEmpty, routesAuth, routesActivate] = routes.ROUTES_AUTH.activate.split('/')

    return locationEmpty === routesEmpty && locationAuth === routesAuth && locationActivate === routesActivate && Boolean(locationToken)
  }, [location.pathname])

  const is2FA = useMemo(() => {
    const [locationEmpty, locationAuth, locationTwoFA] = location.pathname.split('/')
    const [routesEmpty, routesAuth, routesTwoFA] = routes.ROUTES_AUTH.twoFA.split('/')

    return locationEmpty === routesEmpty && locationAuth === routesAuth && locationTwoFA === routesTwoFA
  }, [location.pathname])

  const renderRedirectFromReactRouter = () => user && (returnToFromURLSearch ? <Loader /> : <Navigate to={returnToFromReactRouter} />)

  useEffect(() => {
    if (user && returnToFromURLSearch) {
      window.location.href = returnToFromURLSearch
    }
  }, [returnToFromURLSearch, user])

  return (
    <Center mih={{ xs: 'fit-content', sm: '100vh' }} mt={{ xs: 16, sm: 'unset' }}>
      <Grid.Container>
        <Grid>
          <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 4 }} offset={{ xs: 0, sm: 3, md: 3, lg: 4, xl: 4 }}>
            {isActivate || is2FA ? null : renderRedirectFromReactRouter()}
            <Suspense fallback={<GuestSkeleton />}>{isActivate || is2FA || (!user && !isUserLoading) ? <Outlet /> : null}</Suspense>
          </Grid.Col>
        </Grid>
      </Grid.Container>
    </Center>
  )
}

import { useCallback, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { DateTime } from 'luxon'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { useWindowScroll } from '@affstore-ds/helpers'
import { MainLayoutPropertiesType } from '@affstore-ds/uikit'

import { addCdnToUrl } from '../../../../../shared/assets'
import { DATE_HH_MM } from '../../../../../shared/constants'
import { clearLocalStorageOnLogout } from '../../../../../shared/localStorage'
import { NAV_MENU_DATA } from '../../../../../shared/navigation/NavMenuData'
import { useNavigationContainer } from '../../../../../shared/providers'
import { buttonCopyTranslationKeys } from '../../../../../shared/translations/buttonCopy.translationKeys'
import { commonTranslationKeys } from '../../../../../shared/translations/common.translationKeys'
import { OnboardingToOfferConversionFunnel } from '../../../../../shared/utils'
import { managersInfoTranslationKeys, navMenuButtonLogoutTranslationKeys } from '../translations'
import { getWorkingHours, mapManagerStatusToAvatarElementVariant } from '../utils'

export const useNavBar = (isOnboarding: boolean): MainLayoutPropertiesType['navbarProps'] => {
  const {
    auth: { user, userRefetch, isUserLoading, signOut },
  } = useAuth()

  const { i18n } = useI18n()

  const { redirect } = useNavigationContainer()

  const [_, scrollTo] = useWindowScroll()

  const [activeLink, setActiveLink] = useState<string | undefined>(location.pathname)

  const handleLinkClick = useCallback(
    (nextPath: string | undefined = '') => {
      if (!nextPath) {
        return
      }
      setActiveLink(nextPath)
      scrollTo({ y: 0 })
      redirect(nextPath)

      if (!isUserLoading) {
        userRefetch()
      }
    },
    [scrollTo, setActiveLink, redirect, isUserLoading, userRefetch],
  )

  const managerInfo = useMemo(() => {
    if (!user?.manager || isOnboarding) {
      return undefined
    }

    const info: MainLayoutPropertiesType['navbarProps']['managerInfo'] = {
      photo: user?.manager.photo ? addCdnToUrl(`/assets/img/managers/${user.manager?.photo}`) : '',
      avatarVariant: mapManagerStatusToAvatarElementVariant(user?.manager),
      firstName: user?.manager.first_name || '',
      lastName: user?.manager.last_name || '',
      labels: {
        contactsSectionLabel: i18n.t(managersInfoTranslationKeys.navMenuManagersContacts),
        copyTooltipText: i18n.t(buttonCopyTranslationKeys.tooltipCopied),
        sendMessageLabel: i18n.t(managersInfoTranslationKeys.navMenuButtonSendMessage),
      },
      contacts: user?.manager.alt_contact || [],
    }

    if (user?.manager.working_hours) {
      const { adaptedManagerFromTime, adaptedManagerToTime } = getWorkingHours({ workingHours: user?.manager?.working_hours, locale: i18n.locale() })

      info.work = {
        hours: `${adaptedManagerFromTime.toFormat(DATE_HH_MM)} - ${adaptedManagerToTime.toFormat(DATE_HH_MM)}`,
        days: i18n.t(managersInfoTranslationKeys.managerDaysWork),
      }
    }

    return info
  }, [user?.manager, i18n, isOnboarding])

  const getLinkNewBadge = useCallback(
    (counter?: number | string) => {
      if (!counter) {
        return
      }

      return (Number(counter) || 0) > DateTime.now().toMillis() ? i18n.t(commonTranslationKeys.commonNew) : undefined
    },
    [i18n],
  )

  const links = useMemo(
    () =>
      NAV_MENU_DATA.map(({ dataTestId, ...link }) => ({
        ...link,
        label: i18n.t(link.label),
        disabled: isOnboarding,
        dataTestId: `navbar-nav-menu.item-${dataTestId}`,
        counter: getLinkNewBadge(link.counter),
        children: link.children?.map(({ dataTestId, ...child }) => ({
          ...child,
          dataTestId: `navbar-nav-menu.item-${dataTestId}`,
          label: i18n.t(child.label),
          counter: getLinkNewBadge(link.counter),
          disabled: isOnboarding,
        })),
      })),
    [i18n, getLinkNewBadge, isOnboarding],
  )

  const handleSignOut = useCallback(() => {
    Sentry.setUser(null)
    // logoutFromSurvey()
    clearLocalStorageOnLogout()
    OnboardingToOfferConversionFunnel.clearCookies()
    signOut()
  }, [signOut])

  return {
    withCollapse: false,
    onLinkClick: handleLinkClick,
    links,
    logout: {
      logoutButtonText: i18n.t(navMenuButtonLogoutTranslationKeys.navMenuButtonLogout),
      onLogoutClick: handleSignOut,
    },
    managerInfo,
    activeLink,
  }
}

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export type AdvCashAccountData = {
  __typename?: 'AdvCashAccountData';
  accountOrEmail: Scalars['String'];
};

export type AdvCashAccountDataInput = {
  accountOrEmail: Scalars['String'];
};

export type Advertiser = {
  __typename?: 'Advertiser';
  alias: Scalars['String'];
  created: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  url: AdvertiserUrl;
};

export type AdvertiserUrl = {
  __typename?: 'AdvertiserURL';
  android: Scalars['String'];
  ios: Scalars['String'];
  mix: Scalars['String'];
  pwa: Scalars['String'];
};

export enum AffModelStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Suspended = 'Suspended'
}

export enum AffModels {
  Cpa = 'CPA',
  Lot = 'LOT',
  Revenue = 'REVENUE',
  Spread = 'SPREAD'
}

export type Affiliate = {
  __typename?: 'Affiliate';
  accountId: Scalars['Float'];
  advertiser: Array<AffiliateAdvertiser>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  offerModels: Array<AffiliateOfferModel>;
  paymentAccounts: PaymentAccountCollection;
  paymentMethods: PaymentMethodCollection;
  site: Site;
  siteId: Scalars['String'];
  ticketSettings: TicketSetting;
  tickets: TicketCollection;
};


export type AffiliateTicketsArgs = {
  filters?: InputMaybe<TicketFiltersInput>;
  pagination?: InputMaybe<SlicePaginationInput>;
  sort?: InputMaybe<TicketSortInput>;
};

export type AffiliateAdvertiser = {
  __typename?: 'AffiliateAdvertiser';
  alias: Scalars['String'];
  id: Scalars['ID'];
  traffic: AffiliateAdvertiserTraffic;
  url: AffiliateAdvertiserUrl;
};

export type AffiliateAdvertiserTraffic = {
  __typename?: 'AffiliateAdvertiserTraffic';
  sources?: Maybe<Scalars['String']>;
  sourcesStatus: TrafficSourceStatus;
  status: TrafficStatus;
};

export type AffiliateAdvertiserUrl = {
  __typename?: 'AffiliateAdvertiserURL';
  android: Scalars['String'];
  ios: Scalars['String'];
  mix: Scalars['String'];
  pwa: Scalars['String'];
};

export type AffiliateFiltersInput = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']>>;
  affModel?: InputMaybe<AffModels>;
  affModels?: InputMaybe<Array<AffModels>>;
  affTrack?: InputMaybe<Scalars['String']>;
  affTracks?: InputMaybe<Array<Scalars['String']>>;
  countries?: InputMaybe<Array<Scalars['Int']>>;
  /**
   *
   *     OldCountry id;
   *     Additional values
   *     * -1 -> Unknown country
   *     * -2 -> Regulated country
   *     * -3 -> Non Regulated country
   *
   */
  country?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<DateRangeInput>;
  platformGroup?: InputMaybe<Array<PlatformGroups>>;
  tariff?: InputMaybe<Tariffs>;
};

export enum AffiliateGroupingFields {
  ByAdvertiser = 'ByAdvertiser',
  ByAffTrack = 'ByAffTrack',
  ByAffTrackAndDate = 'ByAffTrackAndDate',
  ByAffiliateModel = 'ByAffiliateModel',
  ByCountry = 'ByCountry',
  ByDay = 'ByDay',
  ByMonth = 'ByMonth',
  ByPlatformGroup = 'ByPlatformGroup',
  ByRegulation = 'ByRegulation',
  ByWeek = 'ByWeek'
}

export type AffiliateMetricsAggregate = {
  __typename?: 'AffiliateMetricsAggregate';
  advertiser: Advertiser;
  affiliateMetrics: AffiliatesMetrics;
  combinedMetrics: CombinedMetrics;
  groupBy: GraphqlMetricsGroupBy;
  mobileMetrics: MobileMetrics;
  visitMetrics: VisitsMetrics;
};

export enum AffiliateMetricsSortablesFields {
  Advertiser = 'Advertiser',
  AffModel = 'AffModel',
  AffTrack = 'AffTrack',
  Clicks = 'Clicks',
  CombinedHits = 'CombinedHits',
  CombinedRegistrations = 'CombinedRegistrations',
  CombinedUniques = 'CombinedUniques',
  Conversion = 'Conversion',
  Country = 'Country',
  Date = 'Date',
  Deals = 'Deals',
  Deposits = 'Deposits',
  Ftd = 'Ftd',
  Hits = 'Hits',
  Installs = 'Installs',
  IsRegulated = 'IsRegulated',
  Leads = 'Leads',
  PlatformGroup = 'PlatformGroup',
  Profit = 'Profit',
  Registrations = 'Registrations',
  Trials = 'Trials',
  Uniques = 'Uniques'
}

export type AffiliateOfferModel = {
  __typename?: 'AffiliateOfferModel';
  name: AffModels;
  status: AffModelStatus;
};

export type AffiliateSortInput = {
  direction?: InputMaybe<SortDirections>;
  field: AffiliateMetricsSortablesFields;
};

export type AffiliatesMetrics = {
  __typename?: 'AffiliatesMetrics';
  deals: Scalars['Float'];
  deposits: Scalars['Float'];
  ftd: Scalars['Float'];
  leads: Scalars['Float'];
  profit: Money;
  registrations: Scalars['Float'];
  trials: Scalars['Float'];
};

export type AmaizAccountData = {
  __typename?: 'AmaizAccountData';
  accountNumber?: Maybe<Scalars['String']>;
  additionalBankId?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankBranchCode?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankCountry?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  correspondentBankName?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  countryOfResidence?: Maybe<Scalars['String']>;
  currency: Currency;
  dateOfIssueOfDocument?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  iban?: Maybe<Scalars['String']>;
  issuedCountryCode?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  receiverAddressCountry?: Maybe<Scalars['String']>;
  receiverAddressState?: Maybe<Scalars['String']>;
  receiverBankName?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AmaizAccountDataInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  additionalBankId?: InputMaybe<Scalars['String']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankCountry?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  correspondentBankName?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  countryOfResidence?: InputMaybe<Scalars['String']>;
  currency: Currency;
  dateOfIssueOfDocument?: InputMaybe<Scalars['DateTime']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  iban?: InputMaybe<Scalars['String']>;
  issuedCountryCode?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  nationality?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  paymentDetails?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  receiverAddressCountry?: InputMaybe<Scalars['String']>;
  receiverAddressState?: InputMaybe<Scalars['String']>;
  receiverBankName?: InputMaybe<Scalars['String']>;
  sortCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  swift?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export enum BalanceType {
  NonRegulated = 'NonRegulated',
  Regulated = 'Regulated'
}

export type BitcoinAccountData = {
  __typename?: 'BitcoinAccountData';
  address: Scalars['String'];
};

export type BitcoinAccountDataInput = {
  address: Scalars['String'];
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CombinedMetrics = {
  __typename?: 'CombinedMetrics';
  /** Conversion of the uniques and registrations */
  conversion: Scalars['Float'];
  /** web unique hits + mobile clicks */
  hits: Scalars['Float'];
  /**
   * Old behaviour: Profit + Referral profit (combined profit). Now Profit = Main.profit field
   * @deprecated Use profit field instead of combinedProfit field. Referral profit was excluded from the general stat.
   */
  profit: Money;
  /** Qty registrations */
  registrations: Scalars['Float'];
  /** web unique visits + mobile clicks */
  uniques: Scalars['Float'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type CreatePaymentAccountInput = {
  accountData: PaymentAccountDataInput;
  paymentMethodId: Scalars['Int'];
};

export type CreatePostbackInput = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  affModel?: InputMaybe<AffModels>;
  affTrack?: InputMaybe<Scalars['String']>;
  event: PostbacksEvent;
  fields?: InputMaybe<Array<PostbacksFieldInput>>;
  name: Scalars['String'];
  url: Scalars['String'];
};

export type CreateTicketInput = {
  subjectId: Scalars['Int'];
  text: Scalars['String'];
};

export enum Currency {
  Ars = 'ARS',
  Bdt = 'BDT',
  Brl = 'BRL',
  Clp = 'CLP',
  Cop = 'COP',
  Egp = 'EGP',
  Eur = 'EUR',
  Gbp = 'GBP',
  Inr = 'INR',
  Lkr = 'LKR',
  Mxn = 'MXN',
  Myr = 'MYR',
  Pen = 'PEN',
  Php = 'PHP',
  Rub = 'RUB',
  Thb = 'THB',
  Uah = 'UAH',
  Usd = 'USD'
}

export type DateRange = {
  __typename?: 'DateRange';
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
};

export type DateRangeInput = {
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
};

export type GraphqlMetricsGroupBy = StatisticsGroupByAdvertiser | StatisticsGroupByAffTrack | StatisticsGroupByAffTrackAndDate | StatisticsGroupByAffiliateModel | StatisticsGroupByCountry | StatisticsGroupByDay | StatisticsGroupByMonth | StatisticsGroupByPlatformGroup | StatisticsGroupByRegulation | StatisticsGroupByWeek;

export type GraphqlReferralMetricsGroupBy = Referral | StatisticsGroupByAffTrack | StatisticsGroupByAffTrackAndDate | StatisticsGroupByDay | StatisticsGroupByMonth | StatisticsGroupByWeek;

export type GraphqlTraderMetricsGroupBy = StatisticsGroupByDay | StatisticsGroupByMonth | StatisticsGroupByWeek | TradersGroupByAdvertiser | TradersGroupByTrader;

export type MobileMetrics = {
  __typename?: 'MobileMetrics';
  clicks: Scalars['Float'];
  installs: Scalars['Float'];
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float'];
  currency: Scalars['String'];
  symbol: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createPaymentAccount: Scalars['Boolean'];
  /** @deprecated use rest api at ms postbacks */
  createPostback: Scalars['Boolean'];
  createTicket: Scalars['Boolean'];
  deletePaymentAccount: Scalars['Boolean'];
  /** @deprecated use rest api at ms postbacks */
  deletePostback: Scalars['Boolean'];
  readTicketMessages: Scalars['Boolean'];
  replyTicket: Scalars['Boolean'];
  requestPaymentAccountConfirmation: Scalars['Boolean'];
  resetDefaultPaymentAccount: Scalars['Boolean'];
  /** @deprecated use rest api at ms postbacks */
  sendTestPostback: SentPostback;
  updatePaymentAccount: Scalars['Boolean'];
  /** @deprecated use rest api at ms postbacks */
  updatePostback: Scalars['Boolean'];
  usePaymentAccountAsDefault: Scalars['Boolean'];
};


export type MutationCreatePaymentAccountArgs = {
  data: CreatePaymentAccountInput;
};


export type MutationCreatePostbackArgs = {
  data: CreatePostbackInput;
};


export type MutationCreateTicketArgs = {
  data: CreateTicketInput;
};


export type MutationDeletePaymentAccountArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePostbackArgs = {
  id: Scalars['Float'];
};


export type MutationReadTicketMessagesArgs = {
  data: ReadTicketMessagesInput;
};


export type MutationReplyTicketArgs = {
  data: ReplyTicketInput;
};


export type MutationRequestPaymentAccountConfirmationArgs = {
  id: Scalars['Int'];
};


export type MutationResetDefaultPaymentAccountArgs = {
  data: ResetDefaultPaymentAccountInput;
};


export type MutationSendTestPostbackArgs = {
  id: Scalars['Float'];
};


export type MutationUpdatePaymentAccountArgs = {
  data: UpdatePaymentAccountInput;
  id: Scalars['Int'];
};


export type MutationUpdatePostbackArgs = {
  data: UpdatePostbackInput;
  id: Scalars['Float'];
};


export type MutationUsePaymentAccountAsDefaultArgs = {
  data: UsePaymentAccountAsDefaultInput;
};

export type NetellerAccountData = {
  __typename?: 'NetellerAccountData';
  /** Nullable for backward compatibility; Aff can be not set country */
  countryCode?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type NetellerAccountDataInput = {
  countryCode: Scalars['String'];
  email: Scalars['String'];
};

export type OtherAccountData = {
  __typename?: 'OtherAccountData';
  value: Scalars['String'];
};

export type PixAccountData = {
  __typename?: 'PIXAccountData';
  abaSwift: Scalars['String'];
  accountAgencyNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  address: Scalars['String'];
  bankName: Scalars['String'];
  beneficiaryFirstName: Scalars['String'];
  beneficiaryLastName: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  documentNumber: Scalars['String'];
  documentType: Scalars['String'];
  email: Scalars['String'];
  payoutAccountTypeCode: Scalars['String'];
  phone: Scalars['String'];
  recipientPixKey?: Maybe<Scalars['String']>;
  recipientPixKeyType?: Maybe<Scalars['String']>;
};

export type PixAccountDataInput = {
  abaSwift?: InputMaybe<Scalars['String']>;
  accountAgencyNumber?: InputMaybe<Scalars['String']>;
  accountNumber: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  bankName: Scalars['String'];
  beneficiaryFirstName: Scalars['String'];
  beneficiaryLastName: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  documentNumber: Scalars['String'];
  documentType: Scalars['String'];
  email: Scalars['String'];
  payoutAccountTypeCode: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  recipientPixKey?: InputMaybe<Scalars['String']>;
  recipientPixKeyType?: InputMaybe<Scalars['String']>;
};

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  accountData: PaymentAccountData;
  affiliateId: Scalars['Float'];
  country?: Maybe<Country>;
  countryCode?: Maybe<Scalars['String']>;
  fee: Money;
  id: Scalars['Float'];
  isConfirmed: Scalars['Boolean'];
  paymentMethod: PaymentMethod;
  paymentMethodId: Scalars['Float'];
};

export type PaymentAccountCollection = {
  __typename?: 'PaymentAccountCollection';
  nodes: Array<PaymentAccount>;
  totalCount: Scalars['Float'];
};

export type PaymentAccountData = {
  __typename?: 'PaymentAccountData';
  advCash?: Maybe<AdvCashAccountData>;
  amaiz?: Maybe<AmaizAccountData>;
  bitcoin?: Maybe<BitcoinAccountData>;
  neteller?: Maybe<NetellerAccountData>;
  other?: Maybe<OtherAccountData>;
  perfectMoney?: Maybe<PerfectMoneyAccountData>;
  pix?: Maybe<PixAccountData>;
  skrill?: Maybe<SkrillAccountData>;
  usdc?: Maybe<UsdcAccountData>;
  usdt?: Maybe<UsdtAccountData>;
  webmoney?: Maybe<WebmoneyAccountData>;
  wireTransfer?: Maybe<WireTransferAccountData>;
};

export type PaymentAccountDataInput = {
  advCash?: InputMaybe<AdvCashAccountDataInput>;
  amaiz?: InputMaybe<AmaizAccountDataInput>;
  bitcoin?: InputMaybe<BitcoinAccountDataInput>;
  neteller?: InputMaybe<NetellerAccountDataInput>;
  perfectMoney?: InputMaybe<PerfectMoneyAccountDataInput>;
  pix?: InputMaybe<PixAccountDataInput>;
  skrill?: InputMaybe<SkrillAccountDataInput>;
  usdc?: InputMaybe<UsdcAccountDataInput>;
  usdt?: InputMaybe<UsdtAccountDataInput>;
  webmoney?: InputMaybe<WebmoneyAccountDataInput>;
  wireTransfer?: InputMaybe<WireTransferAccountDataInput>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  alias: Scalars['String'];
  fee?: Maybe<Money>;
  id: Scalars['Float'];
  minWithdrawalRegular?: Maybe<Money>;
  minWithdrawalRequest?: Maybe<Money>;
  name: Scalars['String'];
};

export type PaymentMethodCollection = {
  __typename?: 'PaymentMethodCollection';
  nodes: Array<PaymentMethod>;
  totalCount: Scalars['Float'];
};

export enum PaymentType {
  ByRequest = 'ByRequest',
  Regular = 'Regular'
}

export type PerfectMoneyAccountData = {
  __typename?: 'PerfectMoneyAccountData';
  account: Scalars['String'];
};

export type PerfectMoneyAccountDataInput = {
  account: Scalars['String'];
};

export type Ping = {
  __typename?: 'Ping';
  value: Scalars['String'];
};

export enum PlatformGroups {
  Android = 'ANDROID',
  Desktop = 'DESKTOP',
  Ios = 'IOS',
  Other = 'OTHER',
  Pwa = 'PWA',
  WebMobile = 'WEB_MOBILE'
}

export type Postback = {
  __typename?: 'Postback';
  advertiserId?: Maybe<Scalars['Int']>;
  affModel?: Maybe<AffModels>;
  affTrack?: Maybe<Scalars['String']>;
  event: PostbacksEvent;
  fields?: Maybe<Array<PostbackField>>;
  id: Scalars['Float'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type PostbackField = {
  __typename?: 'PostbackField';
  alias: Scalars['String'];
  name: PostbacksFieldName;
};

export type PostbacksCollection = {
  __typename?: 'PostbacksCollection';
  nodes: Array<Postback>;
  totalCount: Scalars['Float'];
};

export enum PostbacksEvent {
  Deal = 'Deal',
  Deposit = 'Deposit',
  FirstDeposit = 'FirstDeposit',
  Install = 'Install',
  Lead = 'Lead',
  Payout = 'Payout',
  Registration = 'Registration',
  RepeatDeposit = 'RepeatDeposit'
}

export type PostbacksFieldInput = {
  alias: Scalars['String'];
  name: PostbacksFieldName;
};

export enum PostbacksFieldName {
  Advertiser = 'Advertiser',
  AffModel = 'AffModel',
  AffTrack = 'AffTrack',
  Amount = 'Amount',
  ClickId = 'ClickId',
  CountryCode = 'CountryCode',
  EventId = 'EventId',
  Instrument = 'Instrument',
  Platform = 'Platform',
  PostbackName = 'PostbackName',
  Profit = 'Profit',
  TraderId = 'TraderId'
}

export type PostbacksSortInput = {
  direction?: InputMaybe<SortDirections>;
  field: PostbacksSortableFields;
};

export enum PostbacksSortableFields {
  AffModel = 'AffModel',
  Event = 'Event',
  Id = 'Id',
  Name = 'Name',
  Url = 'Url'
}

export type Query = {
  __typename?: 'Query';
  /**
   * get authorized affiliate
   * @deprecated use REST API /api/v4/users from MS affiliate-users
   */
  affiliate: Affiliate;
  /** get main aggregated affiliate statistics */
  affiliateStatistics: StatisticsAggregateCollection;
  ping: Ping;
  /**
   * get postback by id
   * @deprecated use rest api at ms postbacks
   */
  postback: Postback;
  /**
   * get affiliate postbacks
   * @deprecated use rest api at ms postbacks
   */
  postbacks: PostbacksCollection;
  /** get aggregated referral statistics */
  referralStatistics: ReferralsAggregateCollection;
  /** get summary main aggregated affiliate statistics */
  summaryAffiliateStatistics: SummaryAffiliateMetricsAggregate;
  /** get summary aggregated referral statistics */
  summaryReferralStatistics: SummaryReferralMetrics;
  /** get summary aggregated traders statistics of the affiliate */
  summaryTraderStatistics: SummaryTraderMetrics;
  /** get concrete ticket */
  ticket: Ticket;
  /** get ticket subjects */
  ticketSubjects: TicketSubjectCollection;
  /** get aggregated traders statistics of the affiliate */
  traderStatistics: TradersAggregateCollection;
};


export type QueryAffiliateStatisticsArgs = {
  filters?: InputMaybe<AffiliateFiltersInput>;
  groupBy: AffiliateGroupingFields;
  pagination?: InputMaybe<SlicePaginationInput>;
  sort?: InputMaybe<AffiliateSortInput>;
};


export type QueryPostbackArgs = {
  id: Scalars['Float'];
};


export type QueryPostbacksArgs = {
  pagination?: InputMaybe<SlicePaginationInput>;
  sort?: InputMaybe<PostbacksSortInput>;
};


export type QueryReferralStatisticsArgs = {
  filters?: InputMaybe<ReferralFiltersInput>;
  groupBy: ReferralMetricsGroupingFields;
  pagination?: InputMaybe<SlicePaginationInput>;
  sort?: InputMaybe<ReferralSortInput>;
};


export type QuerySummaryAffiliateStatisticsArgs = {
  filters?: InputMaybe<AffiliateFiltersInput>;
};


export type QuerySummaryReferralStatisticsArgs = {
  filters?: InputMaybe<ReferralFiltersInput>;
};


export type QuerySummaryTraderStatisticsArgs = {
  filters?: InputMaybe<TraderFiltersInput>;
};


export type QueryTicketArgs = {
  id: Scalars['ID'];
};


export type QueryTraderStatisticsArgs = {
  filters?: InputMaybe<TraderFiltersInput>;
  groupBy: TraderMetricsGroupingFields;
  pagination?: InputMaybe<SlicePaginationInput>;
  sort?: InputMaybe<TraderSortInput>;
};

export type ReadTicketMessagesInput = {
  messagesId: Array<Scalars['Int']>;
};

export type Referral = {
  __typename?: 'Referral';
  affTrack: Scalars['String'];
  referralId: Scalars['String'];
  registerDate: Scalars['Date'];
};

export type ReferralAggregate = {
  __typename?: 'ReferralAggregate';
  groupBy: GraphqlReferralMetricsGroupBy;
  metrics: ReferralsMetrics;
};

export type ReferralFiltersInput = {
  affTrack?: InputMaybe<Scalars['String']>;
  affTracks?: InputMaybe<Array<Scalars['String']>>;
  /** Date range of the metrics date */
  metricsDate?: InputMaybe<DateRangeInput>;
  /** Date range of the referral register date */
  registerDate?: InputMaybe<DateRangeInput>;
};

export enum ReferralMetricsGroupingFields {
  ByAffTrack = 'ByAffTrack',
  ByDay = 'ByDay',
  ByMonth = 'ByMonth',
  ByReferral = 'ByReferral',
  ByWeek = 'ByWeek'
}

export enum ReferralMetricsSortablesFields {
  AffTrack = 'AffTrack',
  Hits = 'Hits',
  MetricsDate = 'MetricsDate',
  Profit = 'Profit',
  ReferralId = 'ReferralId',
  ReferralsCount = 'ReferralsCount',
  RegisterDate = 'RegisterDate',
  Uniques = 'Uniques'
}

export type ReferralSortInput = {
  direction?: InputMaybe<SortDirections>;
  field: ReferralMetricsSortablesFields;
};

export type ReferralsAggregateCollection = {
  __typename?: 'ReferralsAggregateCollection';
  nodes: Array<ReferralAggregate>;
  totalCount: Scalars['Int'];
};

export type ReferralsMetrics = {
  __typename?: 'ReferralsMetrics';
  hits: Scalars['Int'];
  profit: Money;
  referralsCount: Scalars['Int'];
  uniques: Scalars['Int'];
};

export type ReplyTicketInput = {
  text: Scalars['String'];
  ticketId: Scalars['Int'];
};

export type ResetDefaultPaymentAccountInput = {
  balanceId?: InputMaybe<Scalars['Int']>;
  balanceType?: InputMaybe<BalanceType>;
  paymentType: PaymentType;
};

export type SentPostback = {
  __typename?: 'SentPostback';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  status: SentPostbackStatus;
  url: Scalars['String'];
};

export enum SentPostbackStatus {
  Error = 'Error',
  Ok = 'Ok'
}

export type Site = {
  __typename?: 'Site';
  alias: Scalars['String'];
  brand: Brand;
  brandId: Scalars['Float'];
  id: Scalars['Float'];
  name: Scalars['String'];
  settings: SiteSetting;
};

export type SiteSetting = {
  __typename?: 'SiteSetting';
  socials: Socials;
  universalUrl: Scalars['String'];
};

export type SkrillAccountData = {
  __typename?: 'SkrillAccountData';
  /** Nullable for backward compatibility; Aff can be not set country */
  countryCode?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type SkrillAccountDataInput = {
  countryCode: Scalars['String'];
  email: Scalars['String'];
};

export type SlicePaginationInput = {
  /** limits the number of rows on the page */
  first?: InputMaybe<Scalars['Int']>;
  /** skip that many rows before beginning to return rows.  */
  skip?: InputMaybe<Scalars['Int']>;
};

export type Socials = {
  __typename?: 'Socials';
  facebookUrl: Scalars['String'];
  instagramUrl: Scalars['String'];
};

export enum SortDirections {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StatisticsAggregateCollection = {
  __typename?: 'StatisticsAggregateCollection';
  nodes: Array<AffiliateMetricsAggregate>;
  totalCount: Scalars['Int'];
};

export type StatisticsGroupByAdvertiser = {
  __typename?: 'StatisticsGroupByAdvertiser';
  advertiser: Advertiser;
};

export type StatisticsGroupByAffTrack = {
  __typename?: 'StatisticsGroupByAffTrack';
  affTrack: Scalars['String'];
};

export type StatisticsGroupByAffTrackAndDate = {
  __typename?: 'StatisticsGroupByAffTrackAndDate';
  affTrack: Scalars['String'];
  date: Scalars['Date'];
};

export type StatisticsGroupByAffiliateModel = {
  __typename?: 'StatisticsGroupByAffiliateModel';
  affModel: AffModels;
};

export type StatisticsGroupByCountry = {
  __typename?: 'StatisticsGroupByCountry';
  country: Country;
  /** @deprecated use country field */
  countryId: Scalars['Float'];
};

export type StatisticsGroupByDay = {
  __typename?: 'StatisticsGroupByDay';
  day: Scalars['Date'];
};

export type StatisticsGroupByMonth = {
  __typename?: 'StatisticsGroupByMonth';
  month: Scalars['Date'];
};

export type StatisticsGroupByPlatformGroup = {
  __typename?: 'StatisticsGroupByPlatformGroup';
  platformGroup: PlatformGroups;
};

export type StatisticsGroupByRegulation = {
  __typename?: 'StatisticsGroupByRegulation';
  /** @deprecated Переходим на Advertiser */
  regulation: Scalars['Boolean'];
};

export type StatisticsGroupByWeek = {
  __typename?: 'StatisticsGroupByWeek';
  week: DateRange;
};

export type Subscription = {
  __typename?: 'Subscription';
  ticketMessageAdded: Ticket;
};

export type SummaryAffiliateMetricsAggregate = {
  __typename?: 'SummaryAffiliateMetricsAggregate';
  affiliateMetrics: SummaryAffiliatesMetrics;
  combinedMetrics: CombinedMetrics;
  countActiveTraders: Scalars['Float'];
  mobileMetrics: SummaryMobileMetrics;
  visitMetrics: SummaryVisitsMetrics;
};

export type SummaryAffiliatesMetrics = {
  __typename?: 'SummaryAffiliatesMetrics';
  deals: Scalars['Float'];
  deposits: Scalars['Float'];
  ftd: Scalars['Float'];
  leads: Scalars['Float'];
  profit: Money;
  registrations: Scalars['Float'];
  trials: Scalars['Float'];
};

export type SummaryCombinedMetrics = {
  __typename?: 'SummaryCombinedMetrics';
  /** Conversion of the uniques and registrations */
  conversion: Scalars['Float'];
  /** web unique hits + mobile clicks */
  hits: Scalars['Float'];
  /**
   * Old behaviour: Profit + Referral profit (combined profit). Now Profit = Main.profit field
   * @deprecated Use profit field instead of combinedProfit field. Referral profit was excluded from the general stat.
   */
  profit: Money;
  /** Qty registrations */
  registrations: Scalars['Float'];
  /** web unique visits + mobile clicks */
  uniques: Scalars['Float'];
};

export type SummaryMobileMetrics = {
  __typename?: 'SummaryMobileMetrics';
  clicks: Scalars['Float'];
  installs: Scalars['Float'];
};

export type SummaryReferralMetrics = {
  __typename?: 'SummaryReferralMetrics';
  hits: Scalars['Int'];
  profit: Money;
  referralsCount: Scalars['Int'];
  uniques: Scalars['Int'];
};

export type SummaryTraderMetrics = {
  __typename?: 'SummaryTraderMetrics';
  cpaDealsAmount: Money;
  deals: Scalars['Float'];
  depositAmount: Money;
  deposits: Scalars['Float'];
  inout: Money;
  pnl: Money;
  pnlRevenue: Money;
  profit: Money;
};

export type SummaryVisitsMetrics = {
  __typename?: 'SummaryVisitsMetrics';
  hits: Scalars['Float'];
  uniques: Scalars['Float'];
};

export enum Tariffs {
  Advanced = 'ADVANCED',
  Standard = 'STANDARD'
}

export type Ticket = {
  __typename?: 'Ticket';
  countUnreadMessages: Scalars['Float'];
  id: Scalars['Float'];
  messages: TicketMessageCollection;
  replyStatus: TicketBlockStatus;
  status: TicketStatus;
  subject: TicketSubject;
  updated: Scalars['DateTime'];
};


export type TicketMessagesArgs = {
  pagination?: InputMaybe<SlicePaginationInput>;
  sort?: InputMaybe<TicketMessageSortInput>;
};

export enum TicketBlockStatus {
  Disabled = 'Disabled',
  DisabledByBlockedAccount = 'DisabledByBlockedAccount',
  DisabledByForbiddenCountry = 'DisabledByForbiddenCountry',
  DisabledByOverflowRateLimit = 'DisabledByOverflowRateLimit',
  DisabledByUnknown = 'DisabledByUnknown',
  Enabled = 'Enabled'
}

export type TicketCollection = {
  __typename?: 'TicketCollection';
  countUnread: Scalars['Float'];
  nodes: Array<Ticket>;
  totalCount: Scalars['Float'];
};

export type TicketFiltersInput = {
  subjectId?: InputMaybe<Scalars['Int']>;
};

export type TicketMessage = {
  __typename?: 'TicketMessage';
  affiliateRead?: Maybe<Scalars['DateTime']>;
  author: TicketMessageAuthor;
  created: Scalars['DateTime'];
  id: Scalars['Float'];
  managerRead?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
};

export type TicketMessageAuthor = {
  __typename?: 'TicketMessageAuthor';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  lastName?: Maybe<Scalars['String']>;
};

export type TicketMessageCollection = {
  __typename?: 'TicketMessageCollection';
  nodes: Array<TicketMessage>;
  totalCount: Scalars['Float'];
};

export type TicketMessageSortInput = {
  direction?: InputMaybe<SortDirections>;
  field: TicketMessageSortableFields;
};

export enum TicketMessageSortableFields {
  Created = 'Created'
}

export type TicketSetting = {
  __typename?: 'TicketSetting';
  createStatus: TicketBlockStatus;
};

export type TicketSortInput = {
  direction?: InputMaybe<SortDirections>;
  field: TicketSortableFields;
};

export enum TicketSortableFields {
  Subject = 'Subject',
  Updated = 'Updated'
}

export enum TicketStatus {
  AnsweredByManager = 'AnsweredByManager',
  UnansweredByManager = 'UnansweredByManager'
}

export type TicketSubject = {
  __typename?: 'TicketSubject';
  alias: TicketSubjectAlias;
  id: Scalars['ID'];
};

export enum TicketSubjectAlias {
  GeneralQuestions = 'GeneralQuestions',
  NewModelRequest = 'NewModelRequest',
  Onboarding = 'Onboarding',
  Other = 'Other',
  PaymentQuestions = 'PaymentQuestions',
  PostbackBanned = 'PostbackBanned',
  StatQuestions = 'StatQuestions',
  TechQuestions = 'TechQuestions',
  VerificationEea = 'VerificationEEA'
}

export type TicketSubjectCollection = {
  __typename?: 'TicketSubjectCollection';
  nodes: Array<TicketSubject>;
  totalCount: Scalars['Float'];
};

export type Trader = {
  __typename?: 'Trader';
  advertiser?: Maybe<Advertiser>;
  affModel: AffModels;
  affTrack: Scalars['String'];
  country: Country;
  cpaDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  isLead?: Maybe<Scalars['Boolean']>;
  platform: PlatformGroups;
  registerDate: Scalars['Date'];
  tariff: Tariffs;
};

export type TraderFiltersInput = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']>>;
  affModel?: InputMaybe<AffModels>;
  affModels?: InputMaybe<Array<AffModels>>;
  affTrack?: InputMaybe<Scalars['String']>;
  affTracks?: InputMaybe<Array<Scalars['String']>>;
  countries?: InputMaybe<Array<Scalars['Int']>>;
  /**
   *
   *     OldCountry id;
   *     Доп. опции
   *     * -1 -> Unknown country
   *     * -2 -> Regulated country
   *     * -3 -> Non Regulated country
   *
   */
  countryId?: InputMaybe<Scalars['Int']>;
  /** Date range, were exists cpa traders */
  cpaDate?: InputMaybe<DateRangeInput>;
  isLead?: InputMaybe<Scalars['Boolean']>;
  metricsDate?: InputMaybe<DateRangeInput>;
  platforms?: InputMaybe<Array<PlatformGroups>>;
  /** Date range of the traders registration */
  registerDate?: InputMaybe<DateRangeInput>;
  tariff?: InputMaybe<Tariffs>;
  traderId?: InputMaybe<Scalars['Int']>;
};

export enum TraderMetricsGroupingFields {
  ByAdvertiser = 'ByAdvertiser',
  ByAffTrack = 'ByAffTrack',
  ByAffiliateModel = 'ByAffiliateModel',
  ByCountry = 'ByCountry',
  ByDay = 'ByDay',
  ByMonth = 'ByMonth',
  ByPlatform = 'ByPlatform',
  ByTrader = 'ByTrader',
  ByWeek = 'ByWeek'
}

export enum TraderMetricsSortablesFields {
  Advertiser = 'Advertiser',
  AffModel = 'AffModel',
  AffTrack = 'AffTrack',
  Country = 'Country',
  CpaDate = 'CpaDate',
  CpaDealsAmount = 'CpaDealsAmount',
  Deals = 'Deals',
  DepositAmount = 'DepositAmount',
  Deposits = 'Deposits',
  Inout = 'Inout',
  IsLead = 'IsLead',
  MetricsDate = 'MetricsDate',
  Platform = 'Platform',
  Pnl = 'Pnl',
  PnlRevenue = 'PnlRevenue',
  Profit = 'Profit',
  RegisterDate = 'RegisterDate',
  Tariff = 'Tariff',
  TraderId = 'TraderId'
}

export type TraderSortInput = {
  direction?: InputMaybe<SortDirections>;
  field: TraderMetricsSortablesFields;
};

export type TradersAggregate = {
  __typename?: 'TradersAggregate';
  groupBy: GraphqlTraderMetricsGroupBy;
  metrics: TradersMetrics;
  trader: Trader;
};

export type TradersAggregateCollection = {
  __typename?: 'TradersAggregateCollection';
  nodes: Array<TradersAggregate>;
  totalCount: Scalars['Int'];
};

export type TradersGroupByAdvertiser = {
  __typename?: 'TradersGroupByAdvertiser';
  advertiser: Advertiser;
};

export type TradersGroupByTrader = {
  __typename?: 'TradersGroupByTrader';
  trader: Trader;
};

export type TradersMetrics = {
  __typename?: 'TradersMetrics';
  cpaDealsAmount: Money;
  deals: Scalars['Float'];
  depositAmount: Money;
  deposits: Scalars['Float'];
  inout: Money;
  pnl: Money;
  pnlRevenue: Money;
  profit: Money;
  tariff: Tariffs;
};

export enum TrafficSourceStatus {
  Approved = 'Approved',
  NotSent = 'NotSent',
  Rejected = 'Rejected',
  Sent = 'Sent'
}

export enum TrafficStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Suspended = 'Suspended'
}

export type UsdcAccountData = {
  __typename?: 'USDCAccountData';
  address: Scalars['String'];
};

export type UsdcAccountDataInput = {
  address: Scalars['String'];
};

export type UsdtAccountData = {
  __typename?: 'USDTAccountData';
  address: Scalars['String'];
};

export type UsdtAccountDataInput = {
  address: Scalars['String'];
};

export type UpdatePaymentAccountInput = {
  accountData: PaymentAccountDataInput;
  paymentMethodId: Scalars['Int'];
};

export type UpdatePostbackInput = {
  affTrack?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Array<PostbacksFieldInput>>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UsePaymentAccountAsDefaultInput = {
  balanceId?: InputMaybe<Scalars['Int']>;
  balanceType?: InputMaybe<BalanceType>;
  paymentAccountId: Scalars['Int'];
  paymentType: PaymentType;
};

export type VisitsMetrics = {
  __typename?: 'VisitsMetrics';
  hits: Scalars['Float'];
  uniques: Scalars['Float'];
};

export type WebmoneyAccountData = {
  __typename?: 'WebmoneyAccountData';
  wmid: Scalars['String'];
};

export type WebmoneyAccountDataInput = {
  wmid: Scalars['String'];
};

export type WireTransferAccountData = {
  __typename?: 'WireTransferAccountData';
  bankAddress: Scalars['String'];
  bankName: Scalars['String'];
  clientAddress: Scalars['String'];
  clientName: Scalars['String'];
  iban: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
  swift: Scalars['String'];
};

export type WireTransferAccountDataInput = {
  bankAddress: Scalars['String'];
  bankName: Scalars['String'];
  clientAddress: Scalars['String'];
  clientName: Scalars['String'];
  iban: Scalars['String'];
  reference?: InputMaybe<Scalars['String']>;
  swift: Scalars['String'];
};

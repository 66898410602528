import React, { FC, PropsWithChildren } from 'react'

import { useAuth } from '@affiliate-cabinet/auth'
import { ErrorBlock } from '@affstore-ds/uikit'

import { ErrorPagesPropertiesInterface } from './types/ErrorPage.types'

import { useErrorPageStyles } from './ErrorPage.styled'

export const ErrorPage: FC<ErrorPagesPropertiesInterface & PropsWithChildren> = (properties) => {
  const { title, description, codeError = 500, children } = properties
  const {
    auth: { user },
  } = useAuth()

  const { classes } = useErrorPageStyles({ isOuterErrorPath: !user })

  return (
    <div className={classes.pageContainer}>
      <ErrorBlock code={codeError} title={title} description={description} mx={16}>
        {children}
      </ErrorBlock>
    </div>
  )
}

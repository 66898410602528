import React, { FC, Suspense, useCallback, useMemo } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '@affiliate-cabinet/auth'
import { Grid, Loader, LoaderProgress, MainLayout } from '@affstore-ds/uikit'

import { useNavBar } from './base/hooks/useNavbar'

import { featuresStatuses } from '../../../config/brands'
import { PAGE_VERTICAL_PADDING } from '../../../shared/constants/spaces'
import { MAIN_NAV_MENU_LOCALSTORAGE_KEY } from '../../../shared/localStorage/constants'
import { routes } from '../../../shared/routes'
import { OnboardingToOfferConversionFunnel } from '../../../shared/utils'
import { getOfferNavigationLink } from '../../../shared/utils/getOfferNavigationLink'
import { getStartPage } from '../../../shared/utils/getStartPage'
import { HeaderMain } from '../../../widgets'

import { MainLogo } from './base/components/MainLogo'
import { isLocationEqualRoute } from './base/utils'

// TODO: [Responsive] Should be removed after full responsive implementation
const isOfferTab = (pathname: string) => {
  const offerTabPattern = /^\/of{2}ers(?:\/[^/]+){3}$/

  return offerTabPattern.test(pathname)
}

export const Main: FC = () => {
  const {
    auth: { user },
  } = useAuth()

  const location = useLocation()

  const isOnboardingPath = useMemo(() => isLocationEqualRoute(location.pathname, routes.ROUTES_ONBOARDING.onboarding), [location.pathname])
  const isOfferListPath = useMemo(() => isLocationEqualRoute(location.pathname, routes.ROUTES_OFFERS.offers), [location.pathname])
  const isAdvertiserListPath = useMemo(() => isLocationEqualRoute(location.pathname, routes.ROUTES_OFFERS.offersAdvertisers), [location.pathname])

  const navBarProperties = useNavBar(isOnboardingPath)

  const defaultLocation = getStartPage()

  const onLogoClick = useCallback(() => navBarProperties.onLinkClick(defaultLocation), [navBarProperties, defaultLocation])

  // TODO: [Responsive] Set responsive container width to undefined for new mobile pages
  const MIN_CONTAINER_WIDTH = useMemo(() => {
    const isResponsivePage =
      isOnboardingPath ||
      isOfferListPath ||
      isAdvertiserListPath ||
      isOfferTab(location.pathname) ||
      location.pathname.includes('404') ||
      location.pathname.includes('500')

    if (!isResponsivePage) {
      return 1024
    }

    return undefined
  }, [isOnboardingPath, location.pathname, isOfferListPath, isAdvertiserListPath])

  if (!isOnboardingPath && user && !user?.is_onboarding_passed) {
    return <Navigate to={routes.ROUTES_ONBOARDING.onboarding} />
  }

  if (isOnboardingPath && user && user?.is_onboarding_passed) {
    const { getMarketingURLParametersFromCookies, COOKIES_PARAMS_OBJECT } = OnboardingToOfferConversionFunnel
    const onBoardingMarketingCookies = getMarketingURLParametersFromCookies()
    const advertiserAlias = onBoardingMarketingCookies.get(COOKIES_PARAMS_OBJECT.m_oto_advertiser)
    const model = onBoardingMarketingCookies.get(COOKIES_PARAMS_OBJECT.m_oto_model)

    if (advertiserAlias && model) {
      return <Navigate to={getOfferNavigationLink({ advertiserAlias, model, active: true })} />
    }

    return <Navigate to={featuresStatuses.isNewOffersPageEnabled ? routes.ROUTES_OFFERS.offersAdvertisers : routes.ROUTES_ROOT.root} />
  }

  return (
    <MainLayout
      localStorageNavMenuKey={MAIN_NAV_MENU_LOCALSTORAGE_KEY}
      headerProps={{
        logo: <MainLogo />,
        onLogoClick,
        defaultLocation,
        content: <HeaderMain />,
        logoWrapperComponent: 'a',
      }}
      navbarProps={navBarProperties}
    >
      <Grid.Container miw={MIN_CONTAINER_WIDTH}>
        <Suspense
          fallback={
            <>
              <LoaderProgress loading={true} timeoutInterval={500} isFixed />
              <Loader my={PAGE_VERTICAL_PADDING} />
            </>
          }
        >
          <Outlet />
        </Suspense>
      </Grid.Container>
    </MainLayout>
  )
}
